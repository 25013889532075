import React from 'react';
import styles from '../styles/Button.module.css'; // Ensure you import the CSS module

const Button = ({ text, onClick, className, icon: Icon, ...props }) => {
  return (
    <button
      className={styles[className]} // Combine CSS module class with dynamic className
      onClick={onClick}
      {...props}
    >
      {Icon && <Icon className={styles.icon_margin} />} {/* Apply the CSS module class */}
      {text}
    </button>
  );
};

export default Button;
