import React from 'react';
import styles from '../styles/ErrorMessageBox.module.css';

const ErrorMessageBox = ({ message }) => {
    return (
        <div className={styles.error_box}>
            <span className={styles.error_message}>
                {message}
            </span>
        </div>
    );
};

export default ErrorMessageBox;
