import React from 'react';
import DisplayPricingPlan from './DisplayPricingPlan';
import PageLayout from '../common/components/PageLayout';
import LoadingSpinner from '../common/components/LoadingSpinner';
import Button from '../common/components/Button';
import styles from './styles/CompleteListPricingPlan.module.css';
import { useNavigate } from 'react-router-dom';

const CompleteListPricingPlan = ({ getPricingPlansLoading, pricingPlans, updatePricingPlanHandler, deletePricingPlanHandler }) => {
    const navigate = useNavigate();
    const navigateToCreatePricingPlanPage = () => navigate('/driver/pricingPlans/create');

    return (
        <div>
            <PageLayout title="Pricing Plans" activeTab="driver-pricing-plans">
                {getPricingPlansLoading ? (
                    <LoadingSpinner />
                ) : (
                    <div>
                        <div className={styles.navigation_button}>
                            <Button
                                text="Create Pricing Plan"
                                className="button_blue"
                                onClick={navigateToCreatePricingPlanPage}
                            />
                        </div>
                        <DisplayPricingPlan
                            pricingPlans={pricingPlans}
                            updatePricingPlanHandler={updatePricingPlanHandler}
                            deletePricingPlanHandler={deletePricingPlanHandler}
                        />
                    </div>
                )}
            </PageLayout>
        </div>
    );
};

export default CompleteListPricingPlan;