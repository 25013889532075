import React from 'react';
import styles from './styles/DisplayPricingPlan.module.css';
import DisplayDeliveryZones from './DisplayDeliveryZones';
import PricingPlanHeader from './PricingPlanHeader';


const DisplayPricingPlan = ({ pricingPlans, updatePricingPlanHandler, deletePricingPlanHandler }) => (
    <div>
        {pricingPlans.length > 0 ? (
            pricingPlans.map((plan) => (
                <div key={plan.id} className={styles.pricing_plan_box}>
                    <PricingPlanHeader
                        pricingPlan={plan}
                        updatePricingPlanHandler={updatePricingPlanHandler}
                        deletePricingPlanHandler={deletePricingPlanHandler}
                    />
                    <DisplayDeliveryZones pricingPlan={plan} />
                </div>
            ))
        ) : (
            <div className={styles.pricing_plan_box}>
                <p>No pricing plans are created.</p>
            </div>
        )}
    </div>
);

export default DisplayPricingPlan;
