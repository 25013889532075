import React, { useState, useEffect } from 'react';
import PageLayout from '../common/components/PageLayout';
import Button from '../common/components/Button';
import PricePlanForm from './PricePlanForm';
import DeliveryZoneForm from './DeliveryZoneForm';
import LoadingSpinner from '../common/components/LoadingSpinner';
import PricingTierForm from './PricingTierForm';
import PriceUpdateForm from './PriceUpdateForm';
import { MdOutlineKeyboardBackspace } from "react-icons/md";

const CompleteCreatePricingPlan = ({ createPricingPlanHandler, cities, getCitiesLoading, navigateToListPricingPlanPage }) => {
    const [submittedPricPlan, setSubmittedPricPlan] = useState(null);
    const [submittedZones, setSubmittedZones] = useState([]);
    const [submittedTiers, setSubmittedTiers] = useState([]);
    const [combinedData, setCombinedData] = useState({ data: {} });

    useEffect(() => {
        const generateCombinedData = () => ({
            ...submittedPricPlan,
            delivery_zones: submittedZones.map(zone => ({
                ...zone,
                cities: zone.cities.map(city => city.id),
                pricing_tiers: submittedTiers
            }))
        });

        setCombinedData(generateCombinedData());
    }, [submittedPricPlan, submittedZones, submittedTiers]);

    const handleSubmitCompletePricingPlan = () => createPricingPlanHandler(combinedData);

    return (
        <div>
            <PageLayout title="Pricing Plans" activeTab="driver-pricing-plans">
                {getCitiesLoading ? (
                    <LoadingSpinner />
                ) : (
                    <div>
                        <Button
                            text="Back"
                            icon={MdOutlineKeyboardBackspace}
                            className="button_arrow"
                            onClick={() => navigateToListPricingPlanPage()}
                        />
                        <PricePlanForm
                            data={submittedPricPlan}
                            setData={setSubmittedPricPlan}
                        />
                        <DeliveryZoneForm
                            data={submittedZones}
                            setData={setSubmittedZones}
                            cities={cities}
                        />
                        <PricingTierForm
                            data={submittedTiers}
                            setData={setSubmittedTiers}
                            pricingPlanData={submittedPricPlan}
                            deliveryZoneData={submittedZones}
                        />
                        {submittedPricPlan && submittedZones.length > 0 && (
                            <PriceUpdateForm
                                data={combinedData}   
                                setData={setCombinedData}                            
                                submittedTiers={submittedTiers}
                                handleSubmitCompletePricingPlan={handleSubmitCompletePricingPlan}
                            />
                        )}
                    </div>
                )}
            </PageLayout>
        </div>
    );
};

export default CompleteCreatePricingPlan;