import React from 'react';
import styles from './styles/DisplayZoneCities.module.css'; // Updated import

const DisplayZoneCities = ({ cities }) => (
    <div className={styles.container}>
        {cities && cities.length > 0 && (
            <>
                <div className={styles.cityHeader}>Cities:</div>
                <div className={styles.cityList}>
                    {cities.map((city) => (
                        <div key={city.id} className={styles.cityNames}>
                            {city.name}
                        </div>
                    ))}
                </div>
            </>
        )}
    </div>
);

export default DisplayZoneCities;
