import React from 'react';
import styles from './styles/DisplayPricingTiers.module.css'; // Updated import

const DisplayPricingTiers = ({ pricingPlan, zone }) => (
    <div>
        {zone.pricing_tiers.length > 0 && (
            <div className={styles.pricing_tiers_table}>
                <div className={styles.pricing_tiers_header}>
                    <div className={styles.pricing_tiers_header_item}>Order Range</div>
                    <div className={styles.pricing_tiers_header_item}>Price</div>
                    <div className={styles.pricing_tiers_header_item}>Period</div>
                </div>
                {zone.pricing_tiers.map((tier) => (
                    <div className={styles.pricing_tiers_row} key={tier.id}>
                        <div className={styles.pricing_tiers_row_item}>
                            {tier.minimum_orders}-{tier.maximum_orders}
                        </div>
                        <div className={styles.pricing_tiers_row_item}>{tier.price} KWD</div>
                        <div className={styles.pricing_tiers_row_item}>
                            {pricingPlan.period.charAt(0).toUpperCase() + pricingPlan.period.slice(1)}
                        </div>
                    </div>
                ))}
            </div>
        )}
    </div>
);

export default DisplayPricingTiers;