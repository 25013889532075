import React, { memo, useEffect, useState } from 'react';
import FormHeader from './FormHeader';
import styles from './styles/PriceUpdateForm.module.css';
import { Formik, Field, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import ErrorMessageBox from '../common/components/ErrorMessageBox'
import { preventEnterKey } from '../../../constants/preventEnterKey';

const PriceUpdateForm = memo(({ data, setData, submittedTiers, handleSubmitCompletePricingPlan }) => {
    const [error, setError] = useState(null);
    const [allowSubmit, setAllowSubmit] = useState(false);

    const onPriceChangeValidation = (values) => {
        for (let zone of values.delivery_zones) {
            for (let tier of zone.pricing_tiers) {
                if (tier.price < 0) {
                    setError("Price value can be zero or positive.");
                    return false;
                }
            }
        }
        setError(null);
        return;
    }

    const handleSubmit = (values) => {
        for (let zone of values.delivery_zones) {
            for (let tier of zone.pricing_tiers) {
                if (tier.price === null || tier.price === undefined || tier.price === '') {
                    setError("Please enter all the prices.");
                    return false;
                }
            }
        }

        const updatedData = {
            ...data,
            delivery_zones: values.delivery_zones.map(zone => ({
                ...zone,
                pricing_tiers: zone.pricing_tiers.map(tier => ({
                    ...tier,
                    price: tier.price
                }))
            }))
        };
        setError(null);
        setAllowSubmit(true);
        setData(updatedData);
    };


    useEffect(() => {
        if (allowSubmit) {
            handleSubmitCompletePricingPlan();
        }
    }, [data])


    return (
        <FormHeader headingText='Zone Pricing'>
            <Formik
                initialValues={data}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                    delivery_zones: Yup.array().of(
                        Yup.object().shape({
                            pricing_tiers: Yup.array().of(
                                Yup.object().shape({
                                    price: Yup.number()
                                        .min(0, 'Price cannot be negative.')
                                })
                            )
                        })
                    )
                })}
                validateOnChange={true}
                validate={(values) => onPriceChangeValidation(values)}
                onSubmit={(values) => handleSubmit(values)}
            >

                {({ values, handleChange, errors }) => {
                    return (
                        <Form onKeyDown={preventEnterKey}>
                            <FieldArray name="delivery_zones">
                                {() => (
                                    <table className={styles.data_table}>
                                        <thead>
                                            <tr>
                                                <th>Zone</th>
                                                <th>Per Order Price</th>
                                                {submittedTiers.map((tier, tierIndex) => (
                                                    <th key={tierIndex}>
                                                        {`${tier.minimum_orders} - ${tier.maximum_orders} ${values.period ? values.period.charAt(0).toUpperCase() + values.period.slice(1) : ''}`}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {values.delivery_zones.map((zone, zoneIndex) => (
                                                <tr key={zoneIndex}>
                                                    <td>{zone.name}</td>
                                                    <td>{zone.price_per_order} KWD</td>
                                                    {zone.pricing_tiers.map((tier, tierIndex) => {
                                                        const error = errors.delivery_zones?.[zoneIndex]?.pricing_tiers?.[tierIndex]?.price;
                                                        return (
                                                            <td key={tierIndex}>
                                                                <Field
                                                                    name={`delivery_zones[${zoneIndex}].pricing_tiers[${tierIndex}].price`}
                                                                    type="number"
                                                                    className={error ? styles.input_field_error : styles.input_field}
                                                                    placeholder={'Price'}
                                                                    onChange={(event) => {
                                                                        setError(null);
                                                                        handleChange(event); // Calls Formik's default onChange behavior
                                                                    }}

                                                                />
                                                            </td>
                                                        )
                                                    })}

                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                )}
                            </FieldArray>
                            <button type="submit" className={`${styles.button_blue}`}>Submit</button>
                        </Form>
                    )
                }}
            </Formik>
            {error && (
                <ErrorMessageBox message={error} />
            )}
        </FormHeader >
    );
});

export default PriceUpdateForm;
