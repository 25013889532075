import * as types from './actionTypes';
import { syncLoading } from '../../../utils/reduxHelpers';

const initialState = {
    pricingPlans: [],
    cities: [],
    success: false,
    loadingHandler: {
        get_pricing_plans_loading: false,
        create_pricing_plan_loading: false,
        update_pricing_plan_loading: false,
        delete_pricing_plan_loading: false,
        get_cities_loading: false
    }
};

const pricingPlanReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PRICING_PLANS_START: {
            return {
                ...state,
                loadingHandler: syncLoading(state.loadingHandler, 'get_pricing_plans_loading', 'start')
            };
        }
        case types.GET_PRICING_PLANS_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                pricingPlans: data,
                success: false,
                loadingHandler: syncLoading(state.loadingHandler, 'get_pricing_plans_loading', 'end')
            };
        }
        case types.GET_PRICING_PLANS_FAIL: {
            return { ...state, loadingHandler: syncLoading(state.loadingHandler, 'get_pricing_plans_loading', 'end') };
        }

        case types.CREATE_PRICING_PLAN_START: {
            return {
                ...state,
                loadingHandler: syncLoading(state.loadingHandler, 'create_pricing_plan_loading', 'start')
            };
        }
        case types.CREATE_PRICING_PLAN_SUCCESS: {
            const { data } = action.payload;

            return {
                ...state,
                pricingPlans: [data, ...state.pricingPlans],
                success: true,
                loadingHandler: syncLoading(state.loadingHandler, 'create_pricing_plan_loading', 'end','success','true')
            };
        }
        case types.CREATE_PRICING_PLAN_FAIL: {
            return { ...state, loadingHandler: syncLoading(state.loadingHandler, 'create_pricing_plan_loading', 'end') };
        }

        case types.UPDATE_PRICING_PLAN_START: {
            return {
                ...state,
                loadingHandler: syncLoading(state.loadingHandler, 'update_pricing_plan_loading', 'start')
            };
        }
        case types.UPDATE_PRICING_PLAN_SUCCESS: {
            const { pricingPlanId, data } = action.payload;

            return {
                ...state,
                pricingPlans: state.pricingPlans.map((item) =>
                    item.id === pricingPlanId ? data : item
                ),
                loadingHandler: syncLoading(state.loadingHandler, 'update_pricing_plan_loading', 'end')
            };
        }
        case types.UPDATE_PRICING_PLAN_FAIL: {
            return { ...state, loadingHandler: syncLoading(state.loadingHandler, 'update_pricing_plan_loading', 'end') };
        }

        case types.DELETE_PRICING_PLAN_START: {
            return {
                ...state,
                loadingHandler: syncLoading(state.loadingHandler, 'delete_pricing_plan_loading', 'start')
            };
        }
        case types.DELETE_PRICING_PLAN_SUCCESS: {
            const { pricingPlanId } = action.payload;
            return {
                ...state,
                pricingPlans: state.pricingPlans.filter(item => item.id !== pricingPlanId),
                loadingHandler: syncLoading(state.loadingHandler, 'delete_pricing_plan_loading', 'end')
            };
        }
        case types.DELETE_PRICING_PLAN_FAIL: {
            return { ...state, loadingHandler: syncLoading(state.loadingHandler, 'delete_pricing_plan_loading', 'end') };
        }

        //Cities

        case types.GET_CITIES_START: {
            return {
                ...state,
                loadingHandler: syncLoading(state.loadingHandler, 'get_cities_loading', 'start')
            };
        }
        case types.GET_CITIES_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                cities: data,
                loadingHandler: syncLoading(state.loadingHandler, 'get_cities_loading', 'end')
            };
        }
        case types.GET_CITIES_FAIL: {
            return { ...state, loadingHandler: syncLoading(state.loadingHandler, 'get_cities_loading', 'end') };
        }

        default:
            return state;
    }
};

export default pricingPlanReducer;
