import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import FormHeader from './FormHeader';
import styles from './styles/PricingTierForm.module.css';
import Button from '../common/components/Button';
import ErrorMessageBox from '../common/components/ErrorMessageBox'
import { preventEnterKey } from '../../../constants/preventEnterKey';

const PricingTierForm = ({ data, setData, pricingPlanData }) => {
    const [error, setError] = useState(null);

    const handleSubmitPricingTiers = (values, { resetForm }) => {
        const newTier = values.pricing_tiers[0];

        // Validate input tier data
        if (newTier.minimum_orders >= newTier.maximum_orders) {
            setError("Maximum orders cannot be less than or equal to minimum orders.");
            return;
        }

        const isOverlapping = data.some(tier =>
            (newTier.minimum_orders >= tier.minimum_orders && newTier.minimum_orders <= tier.maximum_orders) ||
            (newTier.maximum_orders >= tier.minimum_orders && newTier.maximum_orders <= tier.maximum_orders) ||
            (newTier.minimum_orders <= tier.minimum_orders && newTier.maximum_orders >= tier.maximum_orders)
        );

        if (isOverlapping) {
            setError("The new Order Volume Tier overlaps with an existing tier.");
            return;
        }

        // No errors, proceed with updating the data
        setError(null);
        setData(prevTier => {
            const updatedTiers = [...prevTier, ...values.pricing_tiers];
            return updatedTiers.sort((a, b) => a.minimum_orders - b.minimum_orders);
        });
        resetForm();
    };


    const handleRemoveSubmittedTier = index => {
        setError(null);
        setData(prevTier => prevTier.filter((_, i) => i !== index));
    };

    return (
        <div>
            <FormHeader headingText='Order Volume Tiers'>
                <Formik
                    initialValues={{
                        pricing_tiers: [{ minimum_orders: '', maximum_orders: '', price: '' }]
                    }}
                    validationSchema={Yup.object({
                        pricing_tiers: Yup.array().of(
                            Yup.object({
                                minimum_orders: Yup.number().required('Minimum Orders is required.').positive('Minimum Orders must be positive.'),
                                maximum_orders: Yup.number().required('Maximum Orders is required.').positive('Maximum Orders must be positive.'),
                            })
                        )
                    })}
                    onSubmit={handleSubmitPricingTiers}
                >
                    {({ values }) => (
                        <Form onKeyDown={preventEnterKey}>
                            <FieldArray name="pricing_tiers">
                                {() => (
                                    <>
                                        {values.pricing_tiers.map((tier, index) => (
                                            <div key={index} className={styles.form_group}>
                                                <div className={styles.form_group}>
                                                    <label htmlFor={`pricing_tiers[${index}].minimum_orders`}>Minimum Orders</label>
                                                    <Field type="number" id={`pricing_tiers[${index}].minimum_orders`} name={`pricing_tiers[${index}].minimum_orders`} className={styles.form_control} />
                                                    <ErrorMessage name={`pricing_tiers[${index}].minimum_orders`} component="div" className={styles.error_message} />
                                                </div>
                                                <div className={styles.form_group}>
                                                    <label htmlFor={`pricing_tiers[${index}].maximum_orders`}>Maximum Orders</label>
                                                    <Field type="number" id={`pricing_tiers[${index}].maximum_orders`} name={`pricing_tiers[${index}].maximum_orders`} className={styles.form_control} />
                                                    <ErrorMessage name={`pricing_tiers[${index}].maximum_orders`} component="div" className={styles.error_message} />
                                                </div>
                                                <Field type="hidden" id={`pricing_tiers[${index}].price`} name={`pricing_tiers[${index}].price`} value={''} />
                                            </div>
                                        ))}
                                        <button type="submit" className={`${styles.btn} ${styles.btn_primary}`}>Save Tier</button>
                                    </>
                                )}
                            </FieldArray>
                        </Form>
                    )}
                </Formik>
                {error && (
                    <ErrorMessageBox message={error} />
                )}
                {data.length > 0 && (
                    <div className={styles.submitted_data_box}>
                        <div className={styles.submitted_data_heading}>Submitted Order Volume Tiers</div>
                        {data.map((tier, index) => (
                            <div key={index} className={styles.submitted_zone}>
                                <div className={styles.submitted_data_text}>
                                    <p><strong>{`${tier.minimum_orders} - ${tier.maximum_orders} `}</strong>
                                        {`${pricingPlanData ? pricingPlanData.period.charAt(0).toUpperCase() + pricingPlanData.period.slice(1) : ''} Orders`}
                                    </p>
                                </div>
                                <Button text="Remove" onClick={() => handleRemoveSubmittedTier(index)} className="button_red" />
                            </div>
                        ))}
                    </div>
                )}
            </FormHeader>
        </div>
    );
};

export default PricingTierForm;