import axios from 'axios';
import { toast } from 'react-toastify';
import { generateQueryParams } from './utils';
import storage from '../../utils/storage';

const agent = axios.create({
    timeout: 100000,
    headers: {
        'Content-Type': 'application/json;charset=utf-8'
    }
});

agent.interceptors.request.use(req => {
    const token = storage.get('token');
    if (token) {
        req.headers.Authorization = `Bearer ${token}`;
    }

    return req;
});

agent.interceptors.response.use(
    res => res,
    err => {
        const errorMessage = err?.response.status
            ? `${err?.response?.data?.message || err.response?.statusText}`
            : 'Something went wrong.';
        toast.error(errorMessage);
        if (err.response?.status === 401) {
            storage.delete('token');
            storage.delete('userId');
            storage.delete('deliveryCompanyId');
            window.location.href = '/?sessionExpired=true'
        }

        return Promise.reject(err);
    }
);

export const get = ({ url, queryParams }) => {
    const formattedUrl = queryParams ? generateQueryParams(url, queryParams) : url;

    return agent.get(formattedUrl).then(response => response.data);
};

export const post = ({ url, data, isFormData }) => {
    if (isFormData) {
        delete agent.defaults.headers.post['Content-Type'];
    }

    return agent.post(url, data).then(response => response.data);
};

export default agent;
