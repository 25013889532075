import { Link } from 'react-router-dom';

const tabs = [
	{ key: 'driver-leaderboard', label: 'Leaderboard', path: '/driver/leaderboard' },
	{ key: 'driver-order-history', label: 'Order History', path: '/driver/order-history' },
	{ key: 'driver-settings', label: 'Settings', path: '/driver/settings' },
	{ key: 'driver-pricing-plans', label: 'Pricing Plans', path: '/driver/pricingPlans' },
];

export default function Navigation({ activeTab }) {
	return (
		<div className="tabs is-toggle is-fullwidth">
			<ul>
				{tabs.map((tab) => (
					<li
						key={tab.key}
						className={activeTab === tab.key ? 'is-active' : ''}
						aria-current={activeTab === tab.key ? 'page' : undefined}
					>
						<Link to={tab.path}>
							{tab.label}
						</Link>
					</li>
				))}
			</ul>
		</div>
	);
}
