import React from 'react';
import DeliveryCompanyNavigation from '../../../../pages/driver/Navigation';
import styles from '../styles/PageLayout.module.css';

const PageLayout = ({ title, children, activeTab }) => {
  return (
    <div className="container">
      <h1 className="is-size-1 has-text-centered my-5">{title}</h1>
      <DeliveryCompanyNavigation activeTab={activeTab} />
      <div className={styles.main_container}>
        <div className="content">{children}</div>
      </div>
    </div>
  );
};

export default PageLayout;
