class Storage {
    set(key, value) {
        localStorage.setItem(`${key}`, value);
    }

    get(key) {
        try {
            return localStorage.getItem(`${key}`);
        } catch (error) {
            console.log(error);
            return {};
        }
    }

    delete(key) {
        localStorage.removeItem(`${key}`);
    }
}

export default new Storage();
