import { apiBaseUrl } from "./runTimeConst";

const pricingPlan = {
  GET_PRICING_PLANS: (deliveryCompanyId) =>
    `${apiBaseUrl}api/v1/delivery_companies/${deliveryCompanyId}/pricing_plans`,
  CREATE_PRICING_PLAN: (deliveryCompanyId) =>
    `${apiBaseUrl}api/v1/delivery_companies/${deliveryCompanyId}/pricing_plans`,
  UPDATE_PRICING_PLAN: (deliveryCompanyId, pricingPlanId) =>
    `${apiBaseUrl}api/v1/delivery_companies/${deliveryCompanyId}/pricing_plans/${pricingPlanId}`,
  DELETE_PRICING_PLAN: (deliveryCompanyId, pricingPlanId) =>
    `${apiBaseUrl}api/v1/delivery_companies/${deliveryCompanyId}/pricing_plans/${pricingPlanId}`
};

const city = {
  GET_CITIES: () =>
    `${apiBaseUrl}api/v1/delivery_companies/cities`,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...pricingPlan,
  ...city,
}
