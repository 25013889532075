import { call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as PricingPlanAPI from '../../../utils/api/pricingPlan';
import * as CityAPI from '../../../utils/api/city';

export default function* watcherSaga() {
    yield takeEvery(actionTypes.GET_PRICING_PLANS, getPricingPlansWorker);
    yield takeEvery(actionTypes.CREATE_PRICING_PLAN, createPricingPlanWorker);
    yield takeEvery(actionTypes.UPDATE_PRICING_PLAN, updatePricingPlanWorker);
    yield takeEvery(actionTypes.DELETE_PRICING_PLAN, deletePricingPlanWorker);

    //Cities
    yield takeEvery(actionTypes.GET_CITIES, getCitiesWorker);
}

function* getPricingPlansWorker(action) {
    const { deliveryCompanyId } = action.payload;
    yield put(actions.getPricingPlansStart());
    try {
        const { data } = yield call(PricingPlanAPI.getPricingPlans, deliveryCompanyId);
        yield put(actions.getPricingPlansSuccess({ data }));
    } catch (error) {
        yield put(actions.getPricingPlansFail());
    }
}

function* createPricingPlanWorker(action) {
    const { payload, deliveryCompanyId } = action.payload;
    yield put(actions.createPricingPlanStart());
    try {
        const { data } = yield call(PricingPlanAPI.createPricingPlan, deliveryCompanyId, payload);
        yield put(actions.createPricingPlanSuccess({ data }));
        toast.success('Pricing plan has been created successfully');
    } catch (error) {
        yield put(actions.createPricingPlanFail());
    }
}

function* updatePricingPlanWorker(action) {
    const { payload, deliveryCompanyId, pricingPlanId } = action.payload;
    yield put(actions.updatePricingPlanStart());
    try {
        const { data } = yield call(PricingPlanAPI.updatePricingPlan, deliveryCompanyId, pricingPlanId, payload);
        yield put(actions.updatePricingPlanSuccess({ pricingPlanId, data }));
        toast.success('Pricing plan has been updated successfully');
    } catch (error) {
        yield put(actions.updatePricingPlanFail());
    }
}

function* deletePricingPlanWorker(action) {
    const { deliveryCompanyId, pricingPlanId } = action.payload;
    yield put(actions.deletePricingPlanStart());
    try {
        yield call(PricingPlanAPI.deletePricingPlan, deliveryCompanyId, pricingPlanId);
        yield put(actions.deletePricingPlanSuccess({ pricingPlanId }));
        toast.success('Pricing plan was deleted successfully');
    } catch (error) {
        yield put(actions.deletePricingPlanFail());
    }
}


function* getCitiesWorker(action) {
    yield put(actions.getCitiesStart());
    try {
        const { data } = yield call(CityAPI.getCities);
        yield put(actions.getCitiesSuccess({ data }));
    } catch (error) {
        yield put(actions.getCitiesFail());
    }
}