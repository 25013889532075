import { makeHttpActionTypes } from '../../../utils/reduxHelpers';

const REDUCER_NAME = 'PRICING_PLAN';

export const { GET_PRICING_PLANS, GET_PRICING_PLANS_START, GET_PRICING_PLANS_SUCCESS, GET_PRICING_PLANS_FAIL } = makeHttpActionTypes(
    REDUCER_NAME,
    'GET_PRICING_PLANS'
);

export const { CREATE_PRICING_PLAN, CREATE_PRICING_PLAN_START, CREATE_PRICING_PLAN_SUCCESS, CREATE_PRICING_PLAN_FAIL } =
    makeHttpActionTypes(REDUCER_NAME, 'CREATE_PRICING_PLAN');

export const { UPDATE_PRICING_PLAN, UPDATE_PRICING_PLAN_START, UPDATE_PRICING_PLAN_SUCCESS, UPDATE_PRICING_PLAN_FAIL } =
    makeHttpActionTypes(REDUCER_NAME, 'UPDATE_PRICING_PLAN');


export const { DELETE_PRICING_PLAN, DELETE_PRICING_PLAN_START, DELETE_PRICING_PLAN_SUCCESS, DELETE_PRICING_PLAN_FAIL } = makeHttpActionTypes(
    REDUCER_NAME,
    'DELETE_PRICING_PLAN'
);


// Cities

export const { GET_CITIES, GET_CITIES_START, GET_CITIES_SUCCESS, GET_CITIES_FAIL } = makeHttpActionTypes(
    REDUCER_NAME,
    'GET_CITIES'
);
