import React, { useEffect, useCallback } from 'react';
import storage from '../../../utils/storage';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getCities, createPricingPlan } from '../redux/actions';
import CompleteCreatePricingPlan from './CompleteCreatePricingPlan';
import { cityListSelector } from '../redux/selectors';
import { useNavigate } from 'react-router-dom';

const CreatePricingPlan = () => {
  const dispatch = useDispatch();
  const { cities, getCitiesLoading } = useSelector(cityListSelector, shallowEqual);
  const { success } = useSelector(state => state.pricingPlanReducer);
  const navigate = useNavigate();
  const navigateToListPricingPlanPage = useCallback(() => {
    navigate('/driver/pricingPlans');
  }, [navigate]);


  useEffect(() => {
    dispatch(getCities());
  }, [dispatch]);

  useEffect(() => {
    if (success) navigateToListPricingPlanPage();
  }, [success, navigateToListPricingPlanPage]);

  const createPricingPlanHandler = (payload) => {
    const deliveryCompanyId = storage.get('deliveryCompanyId');
    dispatch(createPricingPlan({ deliveryCompanyId, payload }));
  };

  return (
    <CompleteCreatePricingPlan
      createPricingPlanHandler={createPricingPlanHandler}
      cities={cities}
      getCitiesLoading={getCitiesLoading}
      navigateToListPricingPlanPage={navigateToListPricingPlanPage}
    />
  );
};

export default CreatePricingPlan;